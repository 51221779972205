<template>
  <v-container id="purchase" fluid tag="section">
    <v-snackbar v-model="editFlash" :timeout="4000" top>
      <span>Purchase Edit Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="editFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="addFlash" :timeout="4000" top>
      <span>Purchase Add Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="addFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-snackbar v-model="deleteFlash" :timeout="4000" top>
      <span>Purchase Delete Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="deleteFlash = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-data-table
      :headers="columns"
      :items="purchases"
      item-key="_id"
      class="elevation-1"
      hide-default-footer
      disable-pagination
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row>
            <v-col>
              <v-text-field
                @keyup="globalSearch"
                v-model="search"
                label="Search"
                class="mx-4 mt-4"
              ></v-text-field>
            </v-col>
            <v-col>
              <router-link
                v-if="modulePermission.Create"
                :to="{ name: 'Add Purchase' }"
              >
                <v-btn color="primary float-right" dark class="mt-4"
                  >Add Purchase</v-btn
                >
              </router-link>
            </v-col>
          </v-row>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Confirm Delete</v-card-title
              ><v-card-text class="text-center"
                >Are you sure to delete record ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" depressed @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary darken-1"
                  depressed
                  @click="deleteItemConfirm"
                  >Delete</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item._id`]="{ index }">
        {{ numbers[index] }}
      </template>
      <template v-slot:[`item.store_name`]="{ item }">
        {{ item.store_name[0].name }}
      </template>
      <!-- <template v-slot:[`item.notes`]="{ item }">
        {{ item.notes ? "Yes" : "No" }}
      </template> -->
      <template v-slot:[`item.notes`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{
              item.notes ? "Yes" : "No"
            }}</span>
          </template>
          <div style="max-width: 200px;">
            <span>{{ item.notes ? item.notes : "No Notes Available" }}</span>
          </div>
        </v-tooltip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <router-link
          v-if="modulePermission.Update"
          :to="{
            name: 'Edit Purchase',
            params: { id: item._id }
          }"
          ><v-icon class="mr-2">mdi-pencil</v-icon></router-link
        >
        <router-link
          v-if="modulePermission.Read"
          :to="{
            name: 'View Purchase',
            params: { id: item._id, action: 'view' }
          }"
          ><v-icon class="mr-2">mdi-eye</v-icon></router-link
        >
        <!-- <v-icon v-if="modulePermission.Delete" @click="deletePurchase(item)"
          >mdi-delete</v-icon
        > -->
      </template>
    </v-data-table>
    <div class="custom_pagination_design">
      <span>
        Showing <b>{{ startRecord }}</b> to <b>{{ endRecord }}</b> of
        <b>{{ totalRecord }}</b> Records
      </span>
      <v-pagination
        class="float-right"
        v-model="page"
        :length="totalPages"
        total-visible="7"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        @input="handlePageChange"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["edit", "add"],
  data() {
    return {
      moduleName: "Purchases",
      modulePermission: [],
      editFlash: false,
      addFlash: false,
      deleteFlash: false,
      search: "",
      loading: true,
      purchases: [],
      page: 1,
      totalPages: 0,
      startRecord: 0,
      endRecord: 0,
      totalRecord: 0,
      dialogDelete: false
    };
  },
  computed: {
    columns() {
      return [
        {
          text: "Actions",
          value: "actions",
          fixed: true,
          sortable: false,
          width: "100px"
        },
        // { sortable: false, text: "Id", value: "_id", sortable: false },
        // { sortable: false , text: "Store Name", value: "store_name", width: "150px" },
        {
          sortable: false,
          text: "Invoice No",
          value: "invoice_no",
          width: "150px"
        },
        { sortable: false, text: "Date", value: "date", width: "180px" },
        { sortable: false, text: "Tax", value: "tax", width: "100px" },
        {
          sortable: false,
          text: "Total Amount",
          value: "total_amount",
          width: "150px"
        },
        {
          sortable: false,
          text: "Vendor Name",
          value: "vendor_name",
          width: "150px"
        },
        // { sortable: false , text: "Vendor Address", value: "vendor_address", width: "170px" },
        {
          sortable: false,
          text: "Mode Of Payment",
          value: "mode_of_payment",
          width: "180px"
        },
        {
          sortable: false,
          text: "Notes",
          value: "notes",
          width: "180px"
        }
      ];
    }
  },
  methods: {
    getPurchases() {
      if (this.search == "") {
        this.search = null;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "purchase/getData/" +
            this.search +
            "?page=" +
            this.page
        )
        .then(response => {
          if (response.status == 200) {
            this.purchases = response.data.purchases.data;
            this.loading = false;
            this.totalPages = response.data.purchases.last_page;
            this.startRecord = response.data.purchases.from;
            this.endRecord = response.data.purchases.to;
            this.totalRecord = response.data.purchases.total;
            this.numbers = [];
            for (
              let num = response.data.purchases.from;
              num <= response.data.purchases.to;
              num++
            ) {
              this.numbers.push(num);
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    globalSearch() {
      this.loading = true;
      this.getPurchases();
    },
    deletePurchase(id) {
      this.dialogDelete = true;
      this.deleteRecord = id._id;
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "purchase/delete/" +
            this.deleteRecord
        )
        .then(response => {
          if (response.status == 200) {
            this.dialogDelete = false;
            this.deleteRecord = "";
            this.deleteFlash = true;
            this.getPurchases();
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    handlePageChange(value) {
      this.loading = true;
      this.page = value;
      this.getPurchases();
    }
  },
  mounted() {
    this.getPurchases();
    let permissions = JSON.parse(sessionStorage.getItem("user-permission"));
    this.modulePermission = permissions[this.moduleName];
    if (this.edit) {
      this.editFlash = true;
    }
    if (this.add) {
      this.addFlash = true;
    }
  }
};
</script>
